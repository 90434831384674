import React, { useRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const ns = `slider-container`

const MainSlider = ({ layout, mainSlider }) => {
  const [sliderCurrent, setsliderCurrent] = useState(1)
  const [sliderLength, setsliderLength] = useState()

  const sliderRef = useRef(null)

  function downHandler({ key }) {
    if (key === 'ArrowRight') {
      sliderRef.current.slickNext()
    }
    if (key === 'ArrowLeft') {
      sliderRef.current.slickPrev()
    }
  }

  useEffect(() => {
    if (mainSlider.slides) {
      setsliderLength(sliderRef.current.props.children.length)
    }
  }, [sliderLength, sliderCurrent])

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  const handleClick = e => {
    const currentSlide = parseInt(e.target.dataset.index) + 1
    if (currentSlide + 1 === sliderCurrent) {
      sliderRef.current.slickPrev()
    } else if (currentSlide - 1 === sliderCurrent) {
      sliderRef.current.slickNext()
    } else if (currentSlide === 1) {
      sliderRef.current.slickNext()
    } else {
      sliderRef.current.slickPrev()
    }
  }

  const sliderNext = () => {
    sliderRef.current.slickNext()
  }
  const sliderPrev = () => {
    sliderRef.current.slickPrev()
  }

  const options = {
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false,
    dots: false,
    infinite: true,
    centerMode: true,
    centerPadding: '15%',
    settings: 'slick',
    beforeChange(currentSlide, nextSlide) {
      setsliderCurrent(nextSlide + 1)
    },
    responsive: [
      {
        breakpoint: 1027,
        settings: 'unslick',
      },
    ],
  }

  if (!mainSlider.slides) return null

  return (
    <div
      className={`${rootClassnames} ${layout === 'alt' ? `${ns}--alt` : ''}`}
    >
      <Slider ref={sliderRef} {...options}>
        {mainSlider.slides.map((slide, index) => (
          <>
            <img
              key={index}
              src={slide.slide.publicURL}
              alt={slide.image_attribution}
              data-index={index}
              onClick={handleClick}
            />
            <p className={`${ns}__slide-attribution`}>
              {slide.image_attribution}
            </p>
          </>
        ))}
      </Slider>
      <div className={`${ns}__slide-count`}>
        <button type="button" onClick={sliderPrev}>
          <img src="/left-arrow.png" alt="left-arrow" />
        </button>
        <p>
          {sliderCurrent} / {sliderLength}
        </p>
        <button type="button" onClick={sliderNext}>
          <img src="/right-arrow.png" alt="right-arrow" />
        </button>
      </div>
    </div>
  )
}

export default MainSlider

MainSlider.defaultProps = {
  mainSlider: {
    slides: [
      {
        title: 'slide-1',
        url: '/home/home-1.png',
      },
      {
        title: 'slide-2',
        url: '/home/home-2.png',
      },
      {
        title: 'slide-3',
        url: '/home/home-3.png',
      },
      {
        title: 'slide-4',
        url: '/home/home-4.png',
      },
      {
        title: 'slide-5',
        url: '/home/home-5.png',
      },
      {
        title: 'slide-6',
        url: '/home/home-6.png',
      },
      {
        title: 'slide-7',
        url: '/home/home-7.png',
      },
    ],
  },
}
