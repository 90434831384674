import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

const ns = `related`

const Related = ({ related }) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  return (
    <div className={rootClassnames}>
      <div className={`${ns}__related-banner ${ns}__related-banner--left`}>
        <p>RELATED</p>
      </div>
      <div className={`${ns}__related-items`}>
        {related.posts.map((item, index) => (
          <div key={index} className={`${ns}__related-item`}>
            <Link to={item.post_slug}>
              <img src={item.featured_image.publicURL} alt={item.subtitle} />
            </Link>
            <Link to={item.post_slug}>
              <p>{item.title}</p>
            </Link>
            <p>{item.subtitle}</p>
          </div>
        ))}
      </div>
      <div className={`${ns}__related-banner ${ns}__related-banner--right`}>
        <p>RELATED</p>
      </div>
    </div>
  )
}

export default Related
