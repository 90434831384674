import React, { useEffect, useState, useCallback, useRef } from 'react'
import Modal from 'react-modal'
import classNames from 'classnames'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Setup app element for react-modal
Modal.setAppElement('#___gatsby')

const ns = `modal`

const ModalComponent = props => {
  const { triggerModal, setTriggerModal, currentPosition, allSlides } = props

  const [open, setOpen] = useState(false)
  const [sliderCurrent, setSliderCurrent] = useState(1)
  const [sliderLength, setSliderLength] = useState()
  const [node, setRef] = useState(null)

  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  const options = {
    autoplay: false,
    arrows: false,
    dots: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    initialSlide: currentPosition,
    accessibility: true,
    beforeChange(currentSlide, nextSlide) {
      setSliderCurrent(nextSlide + 1)
    },
  }
  const handleModalClose = () => {
    setOpen(false)
    setTriggerModal(false)
    document.body.classList.remove('modal-open')
  }

  useEffect(() => {
    function downHandlerModal({ key }) {
      if (node !== null) {
        if (key === 'ArrowRight') {
          node.slickNext()
        }
        if (key === 'ArrowLeft') {
          node.slickPrev()
        }
      }
    }

    window.addEventListener('keydown', downHandlerModal)
    // Remove event listeners on cleanup
    return () => window.removeEventListener('keydown', downHandlerModal)
  }, [node, open]) // Empty array ensures that effect is only run on mount and unmount

  useEffect(() => {
    setOpen(triggerModal)
    if (open) {
      document.body.classList.add('modal-open')
    }
  }, [open, setOpen, triggerModal])

  const sliderRef = useCallback(
    node => {
      if (node !== null) {
        setRef(node)
        setSliderLength(node.props.children.length)
        node.slickGoTo(currentPosition, true)
      } else {
        setRef(null)
      }
    },
    [currentPosition]
  )

  const sliderNext = () => {
    node.slickNext()
  }
  const sliderPrev = () => {
    node.slickPrev()
  }

  return (
    <Modal
      className={`${rootClassnames} ${open ? 'open' : ''} modal-open`}
      overlayClassName={`${ns}-overlay`}
      isOpen={open}
      onRequestClose={handleModalClose}
      closeTimeoutMS={250}
    >
      <div className={`${ns}__container`}>
        <button
          type="button"
          className={`${ns}__close`}
          onClick={handleModalClose}
        >
          <img src="/close-icon.png" alt="closes modal" />
        </button>
        <div className={`${ns}__content`}>
          <Slider ref={sliderRef} {...options}>
            {allSlides.works.map((slide, index) => (
              <div key={index} className="image--container">
                <img
                  src={slide.image.publicURL}
                  alt={slide.image_attribution}
                />
                <p className="image-attribution-artwork">
                  {slide.image_attribution}
                </p>
              </div>
            ))}
          </Slider>
          <div className={`${ns}__slide-count`}>
            <div className={`${ns}__slide-count-container`}>
              <button type="button" onClick={sliderPrev}>
                <img src="/left-arrow.png" alt="left-arrow" />
              </button>
              <p>
                {sliderCurrent} / {sliderLength}
              </p>
              <button type="button" onClick={sliderNext}>
                <img src="/right-arrow.png" alt="right-arrow" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalComponent
