import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import LayoutExhibition from '../components/LayoutExhibition'
import SideBar from '../components/SideBar'
import MainSlider from '../components/Slider/Slider'
import Related from '../components/Related'
import SelectedWorks from '../components/SelectedWorks'
import ArtistBio from '../components/ArtistBio'
import Modal from '../components/Modal'
import FullPage from '../components/FullPage'

const ns = `exibition-page`

const ExhibitionPost = ({ data }) => {
  const {
    markdownRemark: { frontmatter: content },
  } = data
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  const [openModal, setOpenModal] = useState(false)
  const [postion, setPosition] = useState(0)

  function setModal(e) {
    setOpenModal(true)
    setPosition(e.target.dataset.position)
  }

  useEffect(() => {
    document.body.style.backgroundColor = content.page_background_color
    return function cleanup() {
      document.body.style.backgroundColor = '#ffffff'
    }
  }, [])

  return (
    <LayoutExhibition>
      <div className={rootClassnames}>
        <FullPage content={content.full_page_hero_slider} />
        <div className={`${ns}__container`}>
          <div className={`${ns}__title`}>
            <h2>{content.artist_name}</h2>
            <h1>{content.title}</h1>
          </div>
          <SideBar
            sideBar={{
              dates: content.exhibition_dates,
              artists: content.artists,
              pressReleaseVisibility: content.press_release_link_visibility,
              pressReleaseText: content.press_release_text,
              pressRelease: content.press_release_pdf.publicURL,
              curatedStatus: content.curated_exhibition,
            }}
          />
          <div className={`${ns}__intro`}>
            <div className={`${ns}__intro-body`}>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {content.intro}
              </ReactMarkdown>
            </div>
          </div>
        </div>
        {content.full_page_body_slider.show_section && (
          <FullPage content={content.full_page_body_slider} />
        )}
        <div className={`${ns}__sub-container`}>
          <SelectedWorks
            clickFunc={setModal}
            selectedWorks={content.selected_works.works}
          />
        </div>
        <MainSlider layout="alt" mainSlider={content.slider} />
        {content.social_post.show_section && (
          <div className={`${ns}__sub-container`}>
            <div className={`${ns}__social-post`}>
              <div className="row justify-content-center align-items-center">
                <div
                  className={`col-lg-6 order-1 ${
                    content.social_post.content_order === 'Image, Text'
                      ? 'order-lg-0'
                      : 'order-lg-1'
                  }`}
                >
                  <img
                    src={content.social_post.image.publicURL}
                    alt={content.social_post.image.text}
                  />
                </div>
                <div
                  className={`col-lg-4 order-0  ${
                    content.social_post === 'Image, Text'
                      ? 'order-lg-1'
                      : 'order-lg-0'
                  }`}
                >
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {content.social_post.text}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ArtistBio artistBio={content.artist_bio} />
      {content.related_posts.show_section && (
        <Related related={content.related_posts} />
      )}
      <Modal
        triggerModal={openModal}
        setTriggerModal={setOpenModal}
        currentPosition={postion}
        allSlides={content.selected_works}
      />
    </LayoutExhibition>
  )
}

export default ExhibitionPost

export const exhibitionPostQuery = graphql`
  query ExhibitionPostQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        artist_name
        page_background_color
        exhibition_dates
        artists {
          name
        }
        press_release_link_visibility
        press_release_text
        press_release_pdf {
          publicURL
        }
        curated_exhibition {
          show_curated_by_section
          text
        }
        intro
        full_page_hero_slider {
          slides {
            image {
              image_visible
              image_attribution
              image {
                publicURL
              }
            }
            one_column {
              column_visible
              background_color
              text
              text_color
            }
            video {
              video_visible
              video
              video_attribution
            }
            two_columns {
              column_visible
              background_color
              left_column
              right_column
            }
          }
        }
        full_page_body_slider {
          show_section
          slides {
            image {
              image_visible
              image {
                publicURL
              }
            }
            two_columns {
              column_visible
              left_column
              right_column
              background_color
            }
            video {
              video_visible
              video
              video_attribution
            }
            one_column {
              background_color
              column_visible
              text
              text_color
            }
          }
        }
        selected_works {
          works {
            image_attribution
            image {
              publicURL
            }
          }
        }
        slider {
          show_section
          slides {
            image_attribution
            slide {
              publicURL
            }
          }
        }
        social_post {
          content_order
          show_section
          text
          image {
            publicURL
          }
        }
        artist_bio {
          section_title
          bio
          artist_image {
            publicURL
          }
        }
        related_posts {
          show_section
          posts {
            subtitle
            post_slug
            title
            featured_image {
              publicURL
            }
          }
        }
      }
    }
  }
`
