import React from 'react'
import classNames from 'classnames'

const ns = `artist-bio`

const ArtistBio = ({ artistBio }) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  return (
    <div className={rootClassnames}>
      <div className={`${ns}__bio`}>
        <h3 className="sub-title">{artistBio.section_title}</h3>
        <p>{artistBio.bio}</p>
      </div>
      <div className={`${ns}__image`}>
        <img src={artistBio.artist_image.publicURL} alt="" />
      </div>
    </div>
  )
}

export default ArtistBio

ArtistBio.defaultProps = {
  artistBio: {
    imageUrl: '/exhibition/planes/Portrait/Deana-Lawson-Portrait.jpeg',
    bio: 'Deana Lawson (b. 1979, Rochester, New York) makes photographs that explore the black familiar and its relationship to lore, global histories, and mystery traditions. She transforms observational picture-making into a powerful mode of expression, critique, and celebration. Romance and intimacy between subjects, as well as ritual and spirituality appear throughout Lawson’s work, often within the same image. Her photographs emphasize formal approaches to film commonly associated with both Western and African 20th-century portraiture practices, in addition to appropriation and uses of vernacular imagery. Lawson engages her subjects with intention and intuition alike, in staged situations characterized by the piercing directness of the model’s gaze. With their meticulous mise-en-scènes filled with personal artifacts and decor, these portraits underscore the psychological connections between people and their domestic spaces, fusing biography, symbolism, and cultural observation, and creating expansive images of contemporary personhood. ',
    readMoreUrl: '/',
  },
}
