import React, { useContext } from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'
import AccordionContext from 'react-bootstrap/AccordionContext'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import Accordion from 'react-bootstrap/Accordion'

const ns = `sidebar`
const SideBar = ({ sideBar }) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    )

    const isCurrentEventKey = currentEventKey === eventKey

    return (
      <button
        type="button"
        className={[
          'btn btn-link ',
          isCurrentEventKey ? ' opened ' : ' closed ',
        ]}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    )
  }

  return (
    <div className={rootClassnames}>
      <div className={`${ns}__sidebar`}>
        <div className={`${ns}__heading`}>
          <p>{sideBar.dates}</p>
        </div>
        {sideBar.pressReleaseVisibility && (
          <div className={`${ns}__heading`}>
            <a href={sideBar.pressRelease} download>
              <p>{sideBar.pressReleaseText}</p>
            </a>
          </div>
        )}
        {sideBar.curatedStatus.text && (
          <div className={`${ns}__heading`}>
            <p>CURATED BY {sideBar.curatedStatus.text}</p>
          </div>
        )}
        <div className={`${ns}__artists`}>
          <Accordion>
            <ContextAwareToggle eventKey="0">
              <div className="accordian-title">
                <p>ARTISTS</p>
                <span className="text-right">
                  <span className="opened-icon">+</span>
                  <span className="closed-icon">—</span>
                </span>
              </div>
            </ContextAwareToggle>
            <Accordion.Collapse eventKey="0">
              <ul>
                {sideBar.artists.map((artist, index) => (
                  <li key={index}>{artist.name}</li>
                ))}
              </ul>
            </Accordion.Collapse>
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export default SideBar

SideBar.defaultProps = {
  sideBar: {
    artists: [
      {
        name: 'Brenna Youngblood',
        url: '/',
      },
    ],
  },
}
