import React from 'react'
import classNames from 'classnames'

const ns = `selected-works`

const SelectedWorks = ({ clickFunc, selectedWorks }) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  return (
    <div className={rootClassnames}>
      <h3 className="sub-title">SELECTED WORKS</h3>
      <div className={`${ns}__selected-work`}>
        {selectedWorks.map((image, index) =>
          (index + 1) % 4 === 0 ? (
            <div key={index} className={`${ns}__selected-work-container`}>
              <div className={`${ns}__selected-work-image`}>
                <div key={`${index}-delimeter`} className="w-100" />
                <img
                  src={image.image.publicURL}
                  alt={image.image_attribution}
                  onClick={e => clickFunc(e)}
                  aria-hidden="true"
                  data-position={index}
                />
              </div>
            </div>
          ) : (
            <div key={index} className={`${ns}__selected-work-container`}>
              <div className={`${ns}__selected-work-image`}>
                <img
                  src={image.image.publicURL}
                  alt={image.image_attribution}
                  onClick={e => clickFunc(e)}
                  aria-hidden="true"
                  data-position={index}
                />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default SelectedWorks
