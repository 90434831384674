import React, { useEffect } from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const ns = `full-page`

const FullPageNew = ({ content }) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  useEffect(() => {
    document.body.style.backgroundColor = '#C0ADC1'
    return function cleanup() {
      document.body.style.backgroundColor = '#ffffff'
    }
  }, [])

  const pause = e => {
    const videoEle = e.target.offsetParent.previousElementSibling
    if (videoEle.paused) videoEle.play()
    else videoEle.pause()
  }

  const sound = e => {
    const videoEle = e.target.offsetParent.previousElementSibling
    videoEle.muted = !videoEle.muted
  }

  const fullImage = imageData => (
    <div className="section">
      <div
        className="bg"
        style={{
          backgroundImage: `url('${imageData.image.publicURL}')`,
        }}
      />
      <p className={`${ns}__attribution-text`}>{imageData.image_attribution}</p>
    </div>
  )

  const fullText = textData => (
    <div
      className="section text"
      style={{
        backgroundColor: textData.background_color,
        color: textData.text_color,
      }}
    >
      <ReactMarkdown rehypePlugins={[rehypeRaw]} classNames="full-text">
        {textData.text}
      </ReactMarkdown>
    </div>
  )

  const fullTwoColumn = twoColumnData => (
    <div className="section" style={{ backgroundColor: 'black' }}>
      <div
        className="row no-gutters justify-content-center align-items-center align-content-center two--column"
        style={{ minHeight: '100vh' }}
      >
        <div className="col-8 col-lg-5">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {twoColumnData.left_column}
          </ReactMarkdown>
        </div>
        <div className="col-11 col-lg-5 offset-md-1">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {twoColumnData.right_column}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  )

  const fullVideo = videoData => (
    <div className="section">
      <video className={`${ns}__full-video`} autoPlay loop muted playsInline>
        <source src={videoData.video} type="video/mp4" />
      </video>
      <div className={`${ns}__video-controls`}>
        <div className={`${ns}__video-controls-container`}>
          <div className="pause">
            <button type="button" onClick={e => pause(e)}>
              <img src="/pause-icon.png" alt="" />
            </button>
          </div>
          <div className="sound">
            <button type="button" onClick={sound}>
              <img src="/audio-icon.png" alt="" />
            </button>
          </div>
        </div>
      </div>
      <p className={`${ns}__attribution-video`}>
        {videoData.video.video_attribution}
      </p>
    </div>
  )

  const renderSection = sectionData => {
    if (sectionData.image.image_visible === true) {
      return fullImage(sectionData.image)
    }
    if (sectionData.one_column?.column_visible === true) {
      return fullText(sectionData.one_column)
    }
    if (sectionData.two_columns.column_visible === true) {
      return fullTwoColumn(sectionData.two_columns)
    }
    if (sectionData.video.video_visible === true) {
      return fullVideo(sectionData.video)
    }
  }

  return (
    <div className={rootClassnames}>
      {content.slides.map((item, index) => renderSection(item, index))}
    </div>
  )
}

export default FullPageNew
